//Mobile------------------------------------------------------------------------------------------------------
nav.main-nav {
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		li {
			position: relative;
		}
		.primary-nav-item {
			color: var(--header-text-color);
		}
		a,button {
			text-decoration: none;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}

@include media-breakpoint-down(xl) {
	nav.main-nav {
		position: fixed;
		inset: 0;
		background-color: var(--color-primary);
		z-index: 10;
		transition: all 0.3s;
		transform: translateX(100%);
		&.opened {
			transform: translateX(0);
		}
		.menu-closer {
			padding: 12px;
			position: relative;
			z-index: 10;
			.icon {
				fill: #FFF;
				width: 40px;
				height: 40px;
				flex-shrink: 0;
			}
		}
		.nav-wrapper {
			position: absolute;
			inset: 0;
			overflow-x: hidden;
			overflow-y: auto;
			margin: 48px 24px 32px 24px;
		}
		.nav-scroller {
			position: absolute;
			inset: 0;
		}
		.primary-nav {
			& > li:not(:last-child) {
				border-bottom: 1px solid rgba(#FFF,0.4);
			}
			.primary-nav-item {
				color: #FFF;
				padding: 8px 12px;
				font-size: 20px;
				position: relative;
				.icon {
					fill: #FFF;
					width: 16px;
					height: 16px;
				}
				&.active {
					font-weight: 600;
					&:after {
						content: '';
						width: 2px;
						background-color: #FFF;
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
					}
				}
			}
		}
		.secondary-nav-wrapper {
			position: fixed;
			inset: 0;
			z-index: 1;
			pointer-events: none;
		}
		.secondary-nav {
			background-color: var(--color-primary);
			position: absolute;
			inset: 0;
			overflow-x: hidden;
			overflow-y: auto;
			margin: 48px 24px 32px 0;
			transition: all 0.3s;
			transform: translateX(100%);
			pointer-events: all;
			&.opened {
				transform: translateX(0%);
			}
		}
		.secondary {
			& > li:not(:last-child) {
				border-bottom: 1px solid rgba(#FFF,0.4);
			}
		}
		.secondary-nav-item {
			font-size: 20px;
			padding: 8px;
			color: #FFF;
		}
		.tertiary {
			padding-bottom: 12px;
			padding-left: 24px;
			li:not(:last-child) {
				margin-bottom: 8px;
			}
		}
		a,h2 {
			color: #FFF;
		}
		h2 {
			font-weight: 300;
			font-size : 40px;
			margin-bottom: 24px;
			.back-button {
				display: flex;
				align-items: top;
				justify-content: center;
				margin-top: 12px;
			}
			.icon {
				fill: #FFF;
				width: 18px;
				height: 18px;

			}
		}
	}
}
//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	nav.main-nav {
		ul {
			a,button {
				font-size: 15px;
				transition: var(--transition-default);
			}
			.primary-nav-item {
				height: var(--header-height);
				box-sizing: border-box;
				position: relative;
				padding: 0 14px;
				font-size: 16px;
				transition: var(--transition-default);
				z-index: 1;
				&:after {
					display: block;
					height: 6px;
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					transform-origin: left;
					transform: scaleX(0);
					background-color: var(--bg-light);
					transition: var(--transition-default);
					opacity: 0;
				}
				&.active {
					&:after {
						background-color: var(--color-primary);
						transform: scaleX(1);
						transform-origin: left;
						opacity: 1;
					}
				}
				&.opened {
					&:after {
						background-color: #FFF;
						transform: scaleX(1);
						transform-origin: left;
						opacity: 1;
					}
				}
				&:hover:not(.active):not(.opened){
					color: var(--color-primary);
					&:after {
						//transform: scaleX(1);
						opacity: 1;
					}
				}
			}
		}
		.secondary-nav {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			padding: 0 50px;
			padding-bottom: 48px;
			background-color: var(--color-primary);
			max-height: 0;
			overflow: hidden;
			transition: all 0.5s;
			transform: translateY(-100%);
			pointer-events: none;
			h2 {
				color: #FFF;
				font-weight: 200;
				min-height: 100%;
				padding-top:calc(var(--header-height) + 48px);
				font-size: 55px;
				transform: translateY(50px);
				opacity: 0;
				transition: all 0.3s;
				transition-delay: 0.4s;
			}
			li {
				border-bottom: 1px solid rgba(#FFF,0.4);
				&:first-child {
					border-top: 1px solid rgba(#FFF,0.4);
				}
				&.border-none {
					a,button {
						padding-bottom: 0;
					}
				}
			}
			.secondary {
				padding-top:calc(var(--header-height) + 48px);
				button {
					cursor: default;
					margin-bottom: 8px;
				}
			}
			a,button {
				color: #FFF;
				padding: 12px 16px;
				position: relative;
			}
			.secondary-nav-item[href] {
				&:before {
					content: '';
					display: block;
					position: absolute;
					inset: 0;
					background-color: rgba(#FFF,0.1);
					transition: all 0.3s;
					transform: scaleX(0);
					transform-origin: left;
					opacity: 0;
				}
				&:hover {
					&:before {
						transform: scaleX(1);
						opacity: 1;
					}
				}
			}
			.tertiary {
				padding-bottom: 16px;
				padding-left: 16px;
				display: flex;
				flex-wrap: wrap;
				li,li:first-child {
					border-bottom: none;
					border-top: none;
					font-weight: 300;
				}
				li {
					width: calc(25% - 16px);
				}
				li:not(:nth-child(4)) {
					padding-right: 15px;
					margin-right: 15px;
					line-height: 1.2em;
					border-right: 1px solid rgba(#FFF,0.1);
				}
				a {
					padding: 0;
					font-size: 14px;
					opacity: 0.6;
					transition: all 0.3s;
					&:hover {
						opacity: 1;
					}
				}
			}
			&.opened {
				max-height: 1500px;
				opacity: 1;
				transform: translateY(0);
				pointer-events: all;
				box-shadow: 0px 0px 80px #002B551A;
				h2 {
					opacity: 1;
					transform: translatey(0);
				}
			}
		}
	}
	header.opened {
		nav {
			ul {
				.primary-nav-item {
					color: #FFF;
					&:hover {
						color: #FFF !important;
					}
				}
			}
			.secondary-nav {

			}
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
	nav.main-nav {
		ul {
			a,button {
				font-size: 19px;
			}
			.primary-nav-item {
				padding: 0 18px;
				font-size: 19px;
			}
		}

	}
}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
