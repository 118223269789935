//Mobile------------------------------------------------------------------------------------------------------
.text-intro {
	margin-bottom: var(--padding-x-desk);
	h2 {
		font-size: 40px;
		font-weight: 100;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid var(--border-color-light);
		&.no-border {
			padding-bottom: 0;
			border:none;
		}
	}
	p {
		font-weight: 300;
		font-size: 16px;
	}
	.download-wrapper {

	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.text-intro {
		.file-download {
			min-width: 300px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.text-intro {
		h2 {
			font-size: 50px;
			line-height: normal;
		}
		p {
			font-size: 18px;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
