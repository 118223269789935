//Mobile------------------------------------------------------------------------------------------------------
.image {
	position: relative;
	&.img-3-col {
		padding-top: 50%;
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&[id]:before {
		display: none;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.image {
		&.img-3-col {
			&:after {
				content: '';
				position: absolute;
				inset: 0;
				display: block;
				background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(254,254,254,0) 30%);
			}
			img {
				object-position: right;
			}
		}
	}
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
}
