//Mobile------------------------------------------------------------------------------------------------------
.free-html {
	padding: 24px 0;
	h3 {
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 24px;
	}
	h2 {
		font-size: 40px;
		font-weight: 100;
		margin-bottom: 24px;
	}
	&.lined {
		border-top: 1px solid var(--border-color-light);
		border-bottom: 1px solid var(--border-color-light);
		padding-top: 24px;
		padding-bottom: 24px;
		margin-bottom: 24px;
	}
	p {
		font-weight: 300;
    	font-size: 16px;
	}
	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		margin-bottom: var(--p-margin-bottom);
		font-weight: 300;
    	font-size: 16px;
		li {
			display: flex;
			&:before {
				content: '';
				width: 6px;
				height: 6px;
				display: block;
				background-color: var(--color-primary);
				margin-top: 10px;
				margin-right: 10px;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.free-html {
		padding: 0 0 50px 0;
		h3 {
			font-size: 32px;
			margin-bottom: 40px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.free-html {
		h2 {
			font-size: 60px;
		}
		&.lined {
			padding-top: 50px;
			padding-bottom: 50px;
			margin-bottom: 50px;
		}
		p {
			font-size: 18px;
		}
		ul {
			font-size: 18px;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
