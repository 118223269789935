//Mobile------------------------------------------------------------------------------------------------------
.button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: all 0.3s;
	padding: 12px;
	font-weight: 200;
	font-size: 18px;
	text-decoration: none;
	border: 1px solid transparent;
	.icon {
		flex-shrink: 0;
		transition: all 0.3s;
		width: 18px;
		height: 18px;
	}
	span + .icon {
		margin-left: 8px;
	}
	&.primary {
		background-color: var(--color-primary);
		color: #FFF;
		.icon {
			fill: #FFF;
		}
	}
	&.outline-primary {
		background-color: #FFF;
		color:  var(--color-primary);
		border: 1px solid  var(--color-primary);
		.icon {
			fill: var(--color-primary);
		}
	}
}
.file-download {
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
	border-top: 1px solid var(--border-color-light);
	border-bottom: 1px solid var(--border-color-light);
	padding: 10px 8px;
	position: relative;
	&:before {
		content: '';
		display: block;
		background-color: var(--border-color-lighter);
		position: absolute;
		inset: 0;
		transition: all 0.3s;
		transform-origin: left;
		transform: scaleX(0);
		opacity: 0.5;
	}
	.left {
		position: relative;
	}
	.icon {
		flex-shrink: 0;
		fill: var(--color-primary);
		position: relative;
		&.pdf {
			width: 32px;
			height: 32px;
			fill: var(--border-color)
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.button {
		display: inline-flex;
		width: auto;
		min-width: 320px;
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.button {
		&:hover {
			.icon {
				transform: translateX(-10px);
			}
		}
		&.primary {
			&:hover {
				background-color: var(--color-primary-hover);
			}
		}
	}
	.file-download {
		&:hover {
			&::before {
				transform: scaleX(1);
			}
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
