//Mobile------------------------------------------------------------------------------------------------------
blockquote {
	padding: 24px;
	border-top: 1px solid var(--border-color-light);
	border-bottom: 1px solid var(--border-color-light);
	margin-bottom: 24px;
	.main-text {
		font-size: 30px;
		font-weight: 100;
		margin-bottom: 24px;
		line-height: 1.2em
	};
	.signature {
		font-weight: 600;
	};
	.descr {

	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	blockquote {
		padding: 40px 24px;
		margin-bottom: 50px;
		.main-text {
			font-size: 40px;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
