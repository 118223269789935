//Mobile------------------------------------------------------------------------------------------------------
.card-news {
	.image {
		margin-bottom: 24px;
		padding-top: 50%;
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	p {
		line-height: 1.2em;
		margin-bottom: 24px;
		&.card-date {
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
			margin-bottom: 12px;
		}
	}
	h3 {
		margin-bottom: 24px;
		font-weight: 600;
		&.title-bottom {
			font-weight: 300;
			font-size: 25px;
			a {
				transition: all;
				text-decoration: none;
			}
		}
	}
	&.pdf-card {
		.image {
			padding-top: 0;
			margin-bottom: 0;
			img {
				position: relative;
				height: auto;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
	.card-news {
		h3 {
			&.title-bottom {
				font-weight: 300;
				font-size: 26px;
				a:hover {
					color: var(--color-primary-hover);
				}
			}
		}
	}
}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
