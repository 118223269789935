//Mobile------------------------------------------------------------------------------------------------------
.form-order {
	@extend .product-text;
	.product-text-text {
		color: var(--color-primary);
		font-weight: 400 !important;
		margin-bottom: 24px;
	}
	.form-row {
		margin-bottom: 16px;
		input,textarea {
			border: none;
			background-color: var(--bg-light);
			padding: 10px;
			width: 100%;
		}
	}
	.button {
		min-width: 200px;
		width: 100%;
		margin-bottom: 16px;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.form-order {
		.button {
			min-width: 200px;
			margin-bottom: 0;
			width: auto;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
