//Mobile------------------------------------------------------------------------------------------------------
.backtotop {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	z-index: var(--backtotop-zindex);
	height: var(--backtotop-size-sm);
	width: var(--backtotop-size-sm);
	border-radius: var(--backtotop-radius);
	right: var(--backtotop-position-right-sm);
	bottom: var(--backtotop-position-btm-sm);
	background-color: var(--backtotop-bg-color);
	border: var(--backtotop-border);
	&.animate {
		transform: translateY(50px);
		&.show {
			transform: translateY(0);
		}
	}
	&.collapse {
		transform: scale(0.5);
		&.show {
			transform: scale(1);
		}
	}
	&.show {
		visibility: visible;
		opacity: 1;
	}
	.icon {
		fill: var(--backtotop-icon-color);
		margin: 4px;
		flex-shrink: 0;
		width: 12px;
		height: 12px;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.backtotop {
		height: var(--backtotop-size-lg);
		width: var(--backtotop-size-lg);
		right: var(--backtotop-position-right-lg);
		bottom: var(--backtotop-position-btm-lg);
	}
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.backtotop {
		transition: var(--transition-default);
	}
}
