//Mobile------------------------------------------------------------------------------------------------------
.hero-card {
	position: relative;
	.image {
		height: calc(100vh - 100px);
		position: relative;
		pointer-events: none !important;
		&:after {
			content: '';
			position: absolute;
			inset: 0;
			display: block;
			pointer-events: none;
			background:rgba(0,0,0,0.5)
		}
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			pointer-events: none;
		}
	}
	.hero-texts {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		padding: 30px 30px 60px 30px;
		box-sizing: border-box;
		width: 100%;

	}
	.top-hero-zone {
		transition: all 0.3s;
		transform: translateY(40px);
		opacity: 0;
	}
	.bottom-hero-zone {
		transition: all 0.3s;
		opacity: 0;
	}
	h2 {
		font-size: 40px;
		color: #FFF;
		font-weight: 100;
	}
	p {
		color: #FFF;
		font-size: 16px;
		font-weight: 300;
		margin-bottom: 24px;
	}
	&.positive {
		.image {
			&:after {
				display: none;
			}
		}
		h2 {
			color: var(--text-color);
		}
		p {
			color: var(--text-color);
		}
	}
}
.swiper-slide-active {
	.hero-card {
		.top-hero-zone{
			transform: translateX(0);
			opacity: 1;
		}
		.bottom-hero-zone {
			opacity: 1;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.hero-card {
		.image {
			height: auto;
			min-height: 500px;
			position: relative;
			padding-top: 46.69%;
			&:after {
				content: '';
				position: absolute;
				inset: 0;
				display: block;
				pointer-events: none;
				background: linear-gradient(90deg, rgba(0,0,0,0.7567401960784313) 0%, rgba(0,0,0,0) 45%, rgba(0,0,0,0) 100%);
			}
		}
		p {
			margin-right: 20%;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.hero-card {
		.hero-texts {
			padding-top: var(--padding-x-desk);
			padding-bottom: var(--padding-x-desk);
			padding-left: 50px;
			padding-right: 50px;
		}
		h2 {
			font-size: 60px;
			color: #FFF;
			font-weight: 100;
		}
		p {
			font-size: 20px;
			margin-bottom: 48px;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
