//Mobile------------------------------------------------------------------------------------------------------
.product-text {
	margin-bottom: var(--padding-x-desk);
	&.lined {
		border-top: 1px solid var(--border-color-light);
		padding-top: 24px;
	}
	h2.product-text-title {
		font-size: 40px;
		font-weight: 100;
		margin-bottom: 24px;
		&.small-title, .small-title {
			font-size: 24px;
			font-weight: 300;
		}
	}
	p.product-text-text {
		font-weight: 300;
		font-size: 16px;
	}
	ul {
		padding: 0;
		margin: 0;
		font-weight: 300;
		font-size: 16px;
		list-style-type: none;
		li {
			display: flex;
			&:before {
				content: '';
				width: 6px;
				height: 6px;
				display: block;
				background-color: var(--color-primary);
				margin-top: 10px;
				margin-right: 10px;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.product-text {
		.img-mob-wrapper {
			width: 50%;
			margin: 0 auto;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.product-text {
		&.lined {
			padding-top: 50px;
		}
		h2.product-text-title {
			font-size: 50px;
			line-height: normal;
			&.small-title {
				font-size: 40px;
			}
		}
		p,ul {
			font-size: 18px;
		}
		p.product-text-text {
			font-size: 18px;
		}
		.card-news p {
			font-size: 16px;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
