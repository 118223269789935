//Mobile------------------------------------------------------------------------------------------------------
.sidebar {
	.button {
		.icon {
			transform: rotate(180deg)
		}
	}
	.image {
		opacity: 1 !important;
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		position: relative;
		&:after {
			content: '';
			width: 8px;
			background-color: var(--bg-light);
			display: block;
			left: 0;
			top: 0;
			bottom: 0;
			position: absolute;
		}
		li {
			.heading {
				font-weight: 600;
			}
			a,.heading {
				text-decoration: none;
				color: var(--text-color);
				transition: all 0.3s;
				display: flex;
				padding: 8px 0 8px 30px;
				position: relative;
				transition: all 0.3s;
				display: flex;
				align-items: center;
				.icon {
					margin-right: 8px;
					width: 16px;
					height: 16px;
					transition: all 0.3s;
					fill: var(--color-primary);
				}
				&:after {
					content: '';
					width: 8px;
					background-color: var(--color-primary);
					display: block;
					left: 0;
					top: 0;
					bottom: 0;
					position: absolute;
					z-index: 1;
					transition: all 0.3s;
					transform-origin: top;
					transform: scaleY(0);
				}
				&.active {
					&:after {
						transform: scaleY(1);
					}
				}
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}

@include media-breakpoint-down(lg) {
	.sidebar {
		.sidebar-wrapper {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			z-index: 10;
			background-color: #FFF;
			padding: 24px;
			transition: all 0.3s;
			overflow: hidden;
			height: 0;
			transform: translateY(50px);
			&.active {
				height: 100%;
				transform: translateY(0);
			}
		}
		.submenu-toggler {
			position: fixed;
			bottom: 12px;
			left: 12px;
			right: 12px;
			z-index: 11;
			.icon {
				transform: rotate(180deg);
			}
			&.active {
				.icon {
					transform: rotate(0);
				}
			}
		}
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.sidebar {
		position: sticky;
		top: 124px;
		.sidebar-images {
			.image:not(:first-child) {
				position: absolute;
				top: 0;
				left: 0;
			}
			.image {
				opacity: 0 !important;
				transition: all 0.3s !important;
				&.active {
					opacity: 1 !important;
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.sidebar {
		ul{
			li {
				a {
					&:hover {
						color: var(--color-primary);
						.icon {
							fill: var(--color-primary);
						}
					}
				}
			}
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
