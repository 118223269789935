//Mobile------------------------------------------------------------------------------------------------------
.table,
.product-text table {
	width: 100%;
	thead {
		background-color: var(--color-primary);
		color: #FFF;
	}
	th,td {
		padding: 10px 20px;
		text-align: left;
	}
	th:not(:last-child){
		border-right: 1px solid #FFF;
	}
	td{
		border-right: 1px solid var(--border-color);
	}
	td {
		border-bottom: 1px solid var(--border-color);
	}
	td:first-child {
		border-left: 1px solid var(--border-color);
	}
}
.table-wrapper {
	overflow: hidden;
	overflow-x: auto;
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
