//Mobile------------------------------------------------------------------------------------------------------
.gmap-wrapper {
	margin-bottom: 24px;
	h2 {
		font-weight: 100;
		font-size: 40px;
		margin-bottom: 24px;
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.gmap-wrapper {
		margin-bottom: 50px;
		h2 {
			font-size: 60px;
			margin-bottom: 40px;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
