//Mobile------------------------------------------------------------------------------------------------------
.card-small {
	padding: 12px;
	//border: 1px solid var(--border-color);
	.card-small-img-zone {
		width: 40%;
		flex-shrink: 0;
	}
	.image {
		padding-top: 100%;
		position: relative;
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.card-small-content {
		text-decoration: none;

		&:hover {
			h3 {
				color: var(--color-primary);
			}
		}
	}
	.card-small-text-zone {
		margin-left: 12px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	h3 {
		font-size: 16px;
		margin-bottom: 8px;
		font-weight: 600;
		transition: all 0.3s;
	}
	p {
		font-weight: 300;
		line-height: 1.2em;
		font-size: 16px;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.card-small {
		padding: 24px;
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
