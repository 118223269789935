//Mobile------------------------------------------------------------------------------------------------------
.modal {
	position: fixed;
	inset: 0;
	z-index: var(--modal-zindex);
	display: flex;
	pointer-events: all;
	.modal-overlay {
		position: fixed;
		inset: 0;
		background-color: var(--modal-overlay-bg-color);
		opacity: 1;
	}
	.modal-content {
		margin: auto;
		z-index: 2;
		position: relative;
		background-color: white;
		max-width: var(--modal-max-width);
		width: var(--modal-width);
		padding: var(--modal-padding);
		opacity: 1;
		transform: translateY(0%);
		visibility: visible;
		transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.4s;
	}
	.modal-close-icon {
		width: 32px;
		height: 32px;
		fill: var(--color-primary)
	}
	[data-a11y-dialog-hide] {
		position: absolute;
		right: 0;
		top: 0;
		padding: 8px;
		border-radius: 0;

	}
	&[aria-hidden="true"] {
		pointer-events: none;
		.modal-overlay {
			opacity: 0;
		}
		.modal-content {
			opacity: 0;
			transform: translateY(10%);
			visibility: hidden;
			transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.4s;
		}
	}
	//-modale con video
	&.modal-video {
		button[data-a11y-dialog-hide] {
			transform: translate(100%, -100%);
			.icon {
				fill: white;
			}
		}
		.modal-content {
			padding: var(--modal-video-padding);
		}
	}
	&.search-panel {
		.modal-content {
			background-color: transparent;
			width: 90%;
		}
		.modal-close-icon {
			display: none;
		}
		.modal-close-icon-fixed {
			position: fixed;
			fill: #FFF;
			width: 40px;
			height: 40px;
			top: 24px;
			right: 24px;
		}
		h2 {
			font-weight: 200;
			font-size: 30px;
			margin-bottom: 24px;
			color: #FFF;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.modal {
		.modal-content {
			width: var(--modal-width-desktop);
		}
	}
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
	.modal {
		&.search-panel {
			.modal-close-icon {
				display: none;
			}
			.modal-close-icon-fixed {
				position: fixed;
				fill: #FFF;
				width: 70px;
				height: 70px;
				top: 24px;
				right: 24px;
			}
			h2 {
				font-size: 50px;
			}
		}
	}
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	.modal {
		.modal-content {
			transition: var(--modal-transition);
		}
		.modal-overlay {
			transition: var(--modal-overlay-transition);
		}
	}
}
