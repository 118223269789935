//Mobile------------------------------------------------------------------------------------------------------
.search-input-wrapper {
	position: relative;
	input {
		font-size: 20px;
		font-weight: 300;
		background-color: transparent;
		border: none;
		padding:8px 0;
		border-bottom: 1px solid rgba(#FFF,0.3);
		color: #FFF;
		transition: all 0.3s;
		padding-right: 30px;
		&:focus {
			border-bottom: 1px solid #FFF;
		}
		&::placeholder {
			color: rgba(#FFF,0.5);
		}
	}
	button {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	.icon {
		fill: #FFF;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.search-input-wrapper {
		input {
			font-size: 35px;
			padding:20px 0;
			padding-right: 50px;
			&::placeholder {
				font-size: 35px;
				color: rgba(#FFF,0.5);
			}
		}
		button {

		}
		.icon {
			width: 35px;
			height: 35px;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
