:root {
	//-colors
	--color-primary: #0071ce;
	--color-primary-hover: #04398d;
	--color-primary-dark:#1a1a1a;
	--color-secondary: #b35101;
	--color-secondary-hover: #5f2b00;
	--text-color: #333333;
	--title-color:#3a393e;
	--border-color: #a3a3a3;
	--border-color-light: #eee;
	--border-color-lighter: #fafafa;
	--bg-light: #f4f4f4;
	//-spaces
	--header-height : 100px;
	--padding-x-desk:50px;
	//-header
	--header-text-color:#666;
	//-general
	--body-bg: #fff;
	--body-color: var(--text-color);
	--link-color: var(--color-primary);
	--border-radius: 8px;
	//-fonts
	--font-primary: "Roboto", sans-serif;
	//-typography
	--header-font: var(--font-secondary);
	--header-weight: 500;
	--header-color: var(--color-primary);
	--header-line-height: 1.1;
	--header-margin-bottom: 0.15em;
	--h1-font-size: 3rem;
	--h2-font-size: 2rem;
	--h3-font-size: 1.5rem;
	--h4-font-size: 1.25rem;
	--h5-font-size: 1.125rem;
	--h6-font-size: 1rem;
	--p-font-size: 1rem;
	--p-margin-bottom: 1em;
	--p-line-height: 1.6em;
	--p-color: var(--text-color);
	--hr-size: 1px;
	--hr-color: var(--border-color);
	--hr-margin: 1rem;
	//-transition
	--transition-default: all 0.3s ease-in;
	//-icon
	--icon-size-sm: 16px;
	--icon-size: 24px;
	--icon-size-lg: 32px;
	--icon-size-xl: 40px;
	//-modal
	--modal-zindex: 1000;
	--modal-padding: 24px;
	--modal-video-padding: 0;
	--modal-max-width: 90%;
	--modal-width: 600px;
	--modal-width-desktop: 800px;
	--modal-overlay-bg-color: rgba(43, 46, 56, 0.9);
	--modal-transition: opacity 400ms, transform 400ms;
	--modal-overlay-transition: opacity 200ms;
	// focus outline
	--outline-color: orange;
	--outline-width: 2px;
	//back to top
	--backtotop-zindex: 10;
	--backtotop-size-sm: 40px;
	--backtotop-size-lg: 50px;
	--backtotop-position-right-sm: 25px;
	--backtotop-position-btm-sm: 25px;
	--backtotop-position-right-lg: 25px;
	--backtotop-position-btm-lg: 40px;
	--backtotop-radius: 0;
	--backtotop-bg-color: var(--color-primary);
	--backtotop-border: none;
	--backtotop-icon-color: #fff;

}

//dark theme
@media (prefers-color-scheme: dark) {
	:root {
		//-general
	}
}
