//Mobile------------------------------------------------------------------------------------------------------
body {
	background-color: var(--body-bg);
	color: var(--body-color);
	font-family: var(--font-primary);
}
html {
	scroll-behavior: smooth;
}

a {
	color: var(--link-color);
}

button {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
}

.container-fluid {
	max-width: 1920px;
}
main {
	padding-top: 100px;
}
.lined-title {
	&:after {
		content: '';
		height: 6px;
		background-color: var(--color-primary);
		display: block;
		width: 50px;
		margin-top: 24px;
	}
}
.padding-y-desk {
	padding-top:calc(var(--padding-x-desk) / 2);
	padding-bottom:calc(var(--padding-x-desk) / 2);
}
[id]:before {
	content: '';
	display: block;
	height:      130px;
	margin-top: -130px;
	visibility: hidden;
	pointer-events: none;
 }
 h2[id] {
	pointer-events: none;
 }
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	[id]:before {
		content: '';
		display: block;
		height:      150px;
		margin-top: -150px;
		visibility: hidden;
		pointer-events: none;
	 }
	.padding-x-desk {
		padding-left:var(--padding-x-desk);
		padding-right:var(--padding-x-desk);
	}
	.padding-y-desk {
		padding-top:48px;
		padding-bottom:48px;
	}
	main {
		padding: 0;
		display: block;
		padding-top: var(--header-height);
		margin-top: 0;
	}
	.lined-title {
		&:after {
			content: '';
			height: 6px;
			width: 80px;
			margin-top: 48px;
		}
	}
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	button,
	input[type="checkbox"] + label,
	input[type="radio"] + label {
		&:hover {
			cursor: pointer;
		}
	}
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
}
