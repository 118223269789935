//Mobile------------------------------------------------------------------------------------------------------
.events-form {
	padding-top: 24px;
	padding-bottom: 24px;
	h2 {
		font-weight: 100;
		font-size: 40px;
		margin-bottom: 24px;
		&.medium {
			font-weight: 400;
		}
	}
	label {
		display: block;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
	}
	[id]:before {
		display: none;
	 }
	.form-group {
		margin-bottom: 24px;
	}
	.stepper {
		font-size: 12px;
		background-color: var(--bg-light);
		display: flex;
		span {
			display: flex;
			padding: 8px;
			background-color: var(--color-primary);
			justify-content: flex-end;
			color: #FFF;
		}
	}
	textarea {
		width: 100%;
		border: 1px solid var(--border-color-light);
		transition: all 0.3s;
		&:focus {
			border: 1px solid var(--color-primary);
			outline: none;
		}
	}
	.captcha {
		font-family: "Comic Sans MS", cursive, sans-serif;
		font-style: italic;
		font-weight: bold;
		font-size: 2em;
		padding: 3px;
		border-radius: 5px;
		text-decoration: line-through;
		color: #a0a0a0;
		background-color: #0c0c0c;
		pointer-events: none;
	 }
	 .restart {
		button {
			font-weight: 600;
			color: var(--color-primary);
			.icon {
				fill: var(--color-primary);
			}
		}
	 }
	 .errmsg {
		font-size: 14px;
		color: red;
		font-weight: 600;
	 }
	.radio-wrapper {
		label {
			margin-bottom: 0;
			font-size: 20px;
			font-weight: 300;
			text-transform: none;
			position: relative;
			&:before {
				content: '';
				width: 16px;
				height: 16px;
				display: block;
				border: 1px solid var(--border-color);
				border-radius: 50%;
				margin-right: 8px;
			}
			&:after {
				content: '';
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: var(--color-primary);
				display: block;
				position: absolute;
				left: 3px;
				transition: all 0.3s;
				transform: scale(0);
			}

		}
		input {
			padding: 0;
			margin: 0;
			visibility: hidden;
			position: absolute;
			&:checked + label {
				pointer-events: none;
			}
			&:checked + label:after {
				transform: scale(1);
			}
		}
	}
	fieldset {
		border:1px solid var(--border-color-light);
		padding: 24px;
		legend {
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
	input {
		&[type="text"],
		&[type="email"],
		&[type="tel"],
		&[type="date"],
		&[type="file"]{
			width: 100%;
			padding: 8px 0;
			font-size: 20px;
			font-weight: 300;
			border: none;
			transition: all 0.3s;
			border-bottom: 4px solid var(--border-color-light);
			&::placeholder {
				color: var(--border-color);
			}
			&:focus {
				border-bottom-color:var(--color-primary);
			}
			&:before {
				display: none;
			}
		}
	}
	select {
		width: 100%;
		padding: 8px 0;
		font-size: 20px;
		font-weight: 300;
		border: none;
		transition: all 0.3s;
		border-bottom: 4px solid var(--border-color-light);
		outline: none !important;
		height: 50px;
		&::placeholder {
			color: var(--border-color);
		}
		&:focus {
			border-bottom-color:var(--color-primary);
		}
		&:before {
			display: none;
		}
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.events-form {
		padding-top: 50px;
		padding-bottom: 50px;
		h2 {
			//font-size: 60px;
			margin-bottom: 40px;
		}
		.form-group {
			margin-bottom: 40px;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
