//Mobile------------------------------------------------------------------------------------------------------
.footer {
	background: url('../assets/hero-sanwa.jpeg') no-repeat;
	background-size:cover;
	position: relative;
	&:before {
		content: '';
		display: block;
		position: absolute;
		inset: 0;
		background: rgba(0,0,0,0.5);
	}
	.logo {
		display: block;
		width: 150px;
		margin-bottom: 24px;
	}
	p,a,ul {
		color: #FFF;
		text-decoration: none;
		line-height: 2em;
		margin: 0;
	}
	.footer-row {
		padding: 12px;
		border-top:1px solid  var(--border-color);
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	.social-links {
		color: #FFF;
		.icon {
			fill: #FFF;
		}
		a:hover {
			&::after {
				display: none;
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}

@include media-breakpoint-down(lg) {
	.footer {
		padding-bottom: 56px;
	}

}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.footer {
		.footer-row {
			padding: 40px 30px;
		}
		.logo {
			width: 100%;
			max-width: 230px;
		}
		a:not(.logo) {
			display: inline-block;
			position: relative;
			&:after {
				content: '';
				height: 1px;
				background-color: #FFF;
				display: block;
				position: absolute;
				bottom: 2px;
				left: 0;
				width: 100%;
				transform-origin: left;
				transition: all 0.3s;
				transform: scaleX(0);
			}
			&:hover {
				&:after {
					transform: scaleX(1);
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
