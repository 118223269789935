//Mobile------------------------------------------------------------------------------------------------------
.sections {
	h2 {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 24px;
	}
	&.grey {
		background-color: var(--bg-light);
	}
	&.blue {
		background-color: var(--color-primary);
		p,h2,h3,ul,li {
			color: #FFF;
		}
	}
	&.dark {
		background-color: var(--color-primary-dark);
		p,h2,h3,ul,li {
			color: #FFF;
		}
		.quote-title, .signature {
			color: var(--color-primary);
		}
		.quote-title {
			font-weight: 300;
		}
		.signature {
			color: #fff;
			font-weight: 400;
		}
	}
	.section-title {
		font-weight: 100;
		font-size: 40px;
		margin-bottom: 24px;
	}
	.img-3-col {
		margin-bottom: -25px;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.sections {
		.img-3-col {
			margin-top: -25px;
			margin-right: -12px;
			margin-bottom: -25px;
			min-height: calc(100% + 50px);
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.sections {
		h2 {
			font-size: 18px;
			margin-bottom: 16px;
			&.more-margin {
				margin-bottom: 40px;
			}
		}
		.section-title {
			font-size: 50px;
			margin-bottom: 40px;
		}
		.img-3-col {
			margin-top: -50px;
			margin-right: -62px;
			min-height: calc(100% + 98px);
			margin-bottom: -50px;
		}
		.text-center {
			margin-top: 30px;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
