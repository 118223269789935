a,
button,
input {
	&:focus {
		outline: none;
	}
	&:focus-visible {
		outline: none;
	}
}
