//Mobile------------------------------------------------------------------------------------------------------
.swiper-carousel-container {
	overflow: hidden;
}
.swiper-carousel {
	position: relative;
	.swiper-pagination {
		margin: 12px;
	}
	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: var(--border-color-light);
		margin: 0 4px;
		transition: all 0.3s;
		&.swiper-pagination-bullet-active {
			background-color: var(--color-primary);
		}
	}
}
.card-3-carousel {
	/*&:after {
		content: '';
		display: block;
		width: 1px;
		background-color: var(--border-color);
		position: absolute;
		right: 0;
		top: 0;
		bottom: 20px;
	}*/
	.swiper-wrapper {

	}
	.swiper-slide {
		height: auto;
		.card-small  {
			border-right: 0;
			height: 100%;
		}
	}
}
//-related styles
.card-3-carousel-sidebar {
	&:after {
		content: '';
		display: block;
		width: 1px;
		background-color: var(--border-color);
		position: absolute;
		right: 0;
		top: 0;
		bottom: 20px;
	}
	.swiper-wrapper {

	}
	.swiper-slide {
		height: auto;
		.card-small  {
			border-right: 0;
			height: 100%;
			padding: 12px;
			.card-small-image-zone {
				width: 50%;
			}
			.card-small-text-zone {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			p {
				font-size: 16px;
			}
		}
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
