//Mobile------------------------------------------------------------------------------------------------------
header {
	background-color: #FFF;
	box-shadow: 0px 0px 80px #002B551A;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	.header-wrapper {
		height: var(--header-height);
	}
	.logo {
		width: 120px;
		position: relative;
		z-index: 10;
		position: relative;
		img.secondary {
			position: absolute;
			inset: 0;
			opacity: 0;
			transition: all 0.3s;
		}
	}
	.search-site {
		.icon {
			fill: var(--header-text-color);
			transition: all 0.3s;
		}
	}
	.menu-toggler {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 24px;
		height: 20px;
		margin-left: 12px;
		span {
			display: block;
			height: 2px;
			width: 100%;
			background-color: var(--header-color);
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	header {
		.logo {
			width: 230px;
		}
		&.opened {
			.logo {
				.secondary {
					opacity: 1;
				}
			}
			.search-wrapper {
				position: relative;
			}
			.search-site {
				.icon {
					fill: #FFF;
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
