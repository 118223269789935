//Mobile------------------------------------------------------------------------------------------------------
.simple-cta {
	display: flex;
	align-items: center;
	text-decoration: none;
	color: var(--color-primary);
	.icon {
		fill: var(--color-primary);
		width: 16px;
		height: 16px;
		margin-left: 8px;
		transition: all 0.3s;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.simple-cta {
		&:hover {
			.icon {
				transform: translateX(10px);
			}
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
