//Mobile------------------------------------------------------------------------------------------------------
.pagination-wrapper {
	display: flex;
	justify-content: center;
	ul {
		display: flex;
		list-style-type: none;
		padding: 0;
		margin: 0;
		align-items: center;
	}
	.icon {
		width: 18px;
		height: 18px;
		fill: var(--color-primary);
	}
	a.page-numbers, span.page-numbers {
		display: flex;
		justify-content: center;
		align-items: center;
		width:50px;
		height: 50px;
		transition: all 0.3s;
		border: 1px solid var(--color-primary);
		text-decoration: none;
		margin: 0 8px;
		color: var(--color-primary);
		font-size: 14px;
		font-weight: 600;
		&.current {
			background-color: var(--color-primary);
			color: #FFF;
		}
		&:hover {
			background-color: var(--color-primary);
			color: #FFF;
		}
		&.next,&.prev {
			border: none;
			&:hover {
				background-color: transparent;
				color:var(--color-primary)
			}
		}

	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
