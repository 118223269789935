//Mobile------------------------------------------------------------------------------------------------------
@keyframes rotate {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
 }


.hero-blurred {
	position: relative;
	min-height: 400px;
	&.small {
		min-height: auto;
	}
	background-color: #000;
	transition: all 0.25s ease-out;
	display: flex;
	flex-direction: column;
	place-content: center;
	align-items: center;
	overflow: hidden;

	.hero-blurred-bg {
		--size:400px;
		--speed: 10s;
		--easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
		width: var(--size);
		height: var(--size);
		filter: blur(calc(var(--size) / 5));
		background: linear-gradient(90deg, rgba(52,205,149,1) 0%, rgba(91,55,235,1) 100%);
		animation: rotate var(--speed) var(--easing) alternate infinite;
		border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
		position: absolute;
	}
	.hero-blurred-content {
		position: relative;
		flex-grow: 1;
		align-self: flex-start;
		width: 100%;
	}
	h1 {
		font-size: 40px;
		color: #FFF;
		font-weight: 100;
	}
	.title-wrapper {
		padding: 30px;
	}
	.hero-text {
		padding: 30px;
		color: #FFF;
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.hero-blurred {
		.hero-blurred-bg {
			--size: 600px;
		}
	}
}

@include media-breakpoint-down(lg) {
	.hero-blurred {
		flex-direction: column-reverse;
		.image {
			width: 100%;
			img {
				width: 100%;
			}
		}
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.hero-blurred {
		position: relative;
		.image {
			position: absolute;
			right: 0;
			left:40%;
			top: 0;
			bottom: 0;
			img {
				height: 100%;
				position: absolute;
				width: 100%;
				object-fit: cover;
				object-position: left;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.hero-blurred {
		.hero-blurred-bg {
			--size: 800px;
		}
		h1 {
			font-size: 60px;
		}
		.title-wrapper{
			padding: 0;
			padding-top: var(--padding-x-desk);
			padding-bottom: var(--padding-x-desk);
		}
		.hero-text  {
			padding-left: 50px;
			margin-left: 50px;
			padding-right: 0;
			margin-top: 60px;
			margin-bottom: var(--padding-x-desk);
			padding-top: 0;
			padding-bottom: 0;
			min-height: calc(100% - 110px);
			border-left: 1px solid #FFF;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
